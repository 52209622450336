import React from "react";
//import SlickSlider from "../SlickSlider/SlickSlider";

const MyClientArea = () => {
  
  return (
    <section className="client-area pt-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-two text-center mb-55">
              <h2 className="title">Share Your Dream We’ll Build It</h2>
              <p>
              Have a brand problem that needs to be solved? We’d love to hear about it!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyClientArea;
